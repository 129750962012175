<template>
    <div class="about">
        <div class="header flexRow">
            <div class="headerTitle">扬州市芃雨医疗科技有限公司</div>
            <div class="headerTab flexRow">
                <div :class="[{'selected': tabSelected === index},'headerTabInner']" v-for="(item,index) in tabs"
                     :key="index" @click="gotoHome(index)">{{ item }}
                </div>
            </div>
        </div>
        <div style="height: 120px"></div>
        <div class="headerImg">
            <img style="width: 100%" src="@/static/image/about.png" alt=""/>
        </div>
        <div class="aboutContent">
            <div class="aboutTitle">公司简介</div>
            <div class="aboutBody">
                扬州市芃雨医疗科技有限公司，主要经营范围有：医疗器械技术开发、技术咨询、技术服务、技术转让，一类、二类、三类医疗器械、生物制品，化妆品，橡胶制品、塑料制品，卫生用品、办公用品，针织品，五金交电销售。
            </div>
            <div class="aboutBody">公司现有人员13人，总经理：王亚星，业务经理1人，业务员5人，财务2人，库房2人，内勤1人，司机1人。</div>
            <div class="aboutTitle">医疗器械经营范围</div>
            <div class="aboutBody">2002版批发：三类医疗器械：6804，6807，6808，6809，6810, 6815，6821，6822.，6823，6824，
                6825.，6826，6827,6828，6830，6831，6832，6833，6834，6840体外诊断试剂除外，6845，6846, 6854，6858,
                6863，6864，6865，6866，6870，6877
            </div>
            <div class="aboutBody">2017版批发：三类医疗器械：01，02，03，04，05，06，07，08，09，10，12，13，14，16，17，18，20，21，22，含植入医疗器械</div>
            <div class="aboutBody">2002版批发：二类医疗器械：6801，6802，6803，6804， 6805，6806，6807，6808， 6809，6810, 6812， 6813， 6815，
                6816, 6820, 6821,
                6822, 6823, 6824, 6825, 6826, 6827，6830, 6831， 6832, 6833， 6834，6840体外诊断试剂除外，6841,
                6845,6854,6855,6856，6857, 6858, 6863, 6864, 6865， 6866, 6870
            </div>
            <div class="aboutBody">2017版批发：二类医疗器械：01，02，03， 04，05，06， 07, 08， 09,10, 11, 14, 15, 16,17, 18, 19, 20, 21，
                22
            </div>
            <div class="imgOuter">
                <img style="width: 30%" src="@/static/image/businessScope1.png" alt=""/>
                <img style="width: 30%" src="@/static/image/businessScope2.png" alt=""/>
                <img style="width: 30%" src="@/static/image/businessScope3.png" alt=""/>
            </div>
            <div class="cooperateTitle">诚邀您合作</div>
            <div class="cooperateOuter">
                <div class="cooperateLabel">合作电话：</div>
                <div class="cooperateText">18061179040</div>
                <div class="cooperateLabel">联系人：</div>
                <div class="cooperateText">王亚星 总经理</div>
            </div>
        </div>
        <div class="bottomOuter">
            <div class="bottomContent">
                <div class="contentText">
                    <div class="iconfont icon-dingwei bottomIcon"></div>
                    <div>江苏省扬州市广陵区头桥镇通达路130号</div>
                </div>
                <div class="contentText">
                    <div class="iconfont icon-dianhua bottomIcon"></div>
                    <div>18061179040</div>
                </div>
                <div class="contentText">
                    <div class="iconfont icon-youbian bottomIcon"></div>
                    <div>225109</div>
                </div>
            </div>
            <div class="bottomName">苏ICP备 109029385号 版权所有©️扬州市芃雨医疗科技有限公司</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    data() {
        return {
            tabSelected: 0,
            tabs: ['公司首页', '公司介绍', '公司地址', '联系我们'],
        }
    },
    components: {},
    methods: {
        gotoHome(index) {
            this.$router.push({
                path: '/',
                query: {
                    scrollIndex: index + 1
                }
            })
        }
    },
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
.about {
    .flexRow {
        display: flex;
        flex-direction: row;
    }

    .header {
        height: 120px;
        width: 80%;
        padding: 0 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        background-color: #FFFFFF;
        z-index: 999;

        .headerTitle {
            font-weight: 650;
            font-size: 2rem;
        }

        .headerTab {
            align-items: center;
            justify-content: flex-end;

            .headerTabInner {
                font-size: 1rem;
                margin-left: 2rem;
                margin-right: 0.5rem;
                cursor: pointer;
                width: 6rem;
                height: 2.5rem;
                border-radius: 2rem;
                line-height: 2.5rem;
            }

            .selected {
                background-color: #36a9ce;
                color: #FFFFFF;
            }
        }
    }

    .headerImg {
        width: 100%;
    }

    .aboutContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        .aboutTitle {
            color: #00a9ac;
            font-size: 2.5rem;
            margin: 4rem 0 1rem 0;
            width: 78rem;
            text-align: start;
            font-weight: bold;
        }

        .aboutBody {
            color: #626262;
            font-size: 1.1rem;
            width: 78rem;
            line-height: 3rem;
            text-align: start;
        }

        .imgOuter {
            margin-top: 3rem;
            width: 78rem;
            display: flex;
            justify-content: space-between;
        }

        .cooperateTitle {
            font-weight: bold;
            font-size: 1.3rem;
            margin-top: 3rem;
            text-align: center;
            width: 78rem;
        }

        .cooperateOuter {
            display: flex;
            width: 78rem;
            flex-direction: row;
            justify-content: center;
            margin: 0.5rem 0 2rem 0;

            .cooperateLabel {
                font-size: 1.3rem;
                font-weight: bold;
                margin-left: 0.5rem;
                height: 2rem;
                line-height: 2rem;
            }

            .cooperateText {
                font-size: 1.3rem;
                height: 2rem;
                line-height: 2rem;
                font-weight: 400;
            }
        }

    }

    .bottomOuter {
        background-color: #212832;
        height: 14rem;
        color: #CACACA;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bottomContent {
            display: flex;
            flex-direction: row;
            margin-top: 5rem;
            width: 100%;
            justify-content: center;

            .bottomIcon {
                font-size: 1.2rem;
                margin-right: 0.5rem;
            }

            .contentText {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 12%;

            }
        }

        .bottomName {
            margin-top: 3rem;
        }
    }
}
</style>
